const offset = 72; // 228px nonsticky - 156px sticky

document.addEventListener("turbo:load", () => {

  const navbar = document.getElementById("header")
  const navbar_corporate = document.getElementById("header_corporate")
  const offset_indicator = document.getElementById("offset_indicator")
  const breadcrumb = document.getElementById("breadcrumb")
  const topBtn = document.querySelector(".top-btn")

  window.onscroll = function() {
    stickyNavbarToggle()
    toTopToggle()
  }

  stickyNavbarToggle()
  toTopToggle()

  topBtn.onclick = () => window.scrollTo({ top: 0, behavior: "smooth" })

  function toTopToggle() {
    topBtn.style.opacity = window.pageYOffset > 500 ? 1 : 0
  }

  function stickyNavbarToggle() {
    if (window.pageYOffset >= offset) {
      navbar.classList.add("sticky")
    } else {
      navbar.classList.remove("sticky")
    }

    if (window.pageYOffset > 0) {
      navbar.classList.add("scroll")
    } else {
      navbar.classList.remove("scroll")
    }
 
    if (navbar_corporate) {
      let offset_corporate = offset_indicator.offsetTop - 156
      
      if (window.pageYOffset >= offset_corporate) {
        navbar.classList.add("sticky-corporate")
        navbar_corporate.classList.add("sticky")
        breadcrumb.classList.add("sticky")
      } else {
        navbar.classList.remove("sticky-corporate")
        navbar_corporate.classList.remove("sticky")
        breadcrumb.classList.remove("sticky")
      }

    }
  }  
 
})
